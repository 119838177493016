import React, { useState, useEffect } from 'react';
import { space, layout } from 'styled-system';
import styled, { css } from 'styled-components';

import type { FormFieldStatus, FormFieldProps } from '../../../../react-components/src';
import { Icon, Text, th, lighten } from '../../../../react-components/src';

import { COUNTRY_CODES, StateEntry, indiaStates, usStates } from '../../../types';

function getStates(country: string) {
  if (country === COUNTRY_CODES.IN) return indiaStates;

  return usStates;
}

const filterState =
  (inputState: string) =>
  ({ name }: StateEntry) => {
    const sanitizedInput = inputState || '';
    return name.toLowerCase().startsWith(sanitizedInput.toLowerCase());
  };

const StateField: React.FunctionComponent<FormFieldProps> = ({ formValues, status, value, name, onChange, onBlur }) => {
  const selectedCountry: string = formValues.address.country;
  const statesToUse = getStates(selectedCountry);

  const [expanded, setExpanded] = useState(false);
  const [stateInput, setStateInput] = useState(() => (!value ? '' : statesToUse.byCode(value)));

  const toggleMenu = () => {
    setExpanded((s) => !s);
  };

  const selectState = (stateCode: string) => {
    onChange(name)(stateCode);
    setStateInput(statesToUse.byCode(stateCode));
  };

  const selectStateOnMouseDown = (stateCode: string) => () => {
    selectState(stateCode);
  };

  const setState = (e: React.FormEvent<HTMLInputElement>) => {
    if (statesToUse.byCode(e.currentTarget.value)) {
      selectState(e.currentTarget.value);
    } else {
      onChange(name)('invalid');
      setStateInput(e.currentTarget.value);
    }
  };

  return (
    <Relative>
      <Root status={status}>
        <StateInput
          id={name}
          required
          value={stateInput}
          onBlur={(e) => {
            toggleMenu();
            onBlur(e);
          }}
          onFocus={toggleMenu}
          onChange={setState}
        />
        <Icon name={expanded ? 'caretUp' : 'caretDown'} color={'colors.textPrimary'} />
      </Root>
      {expanded && (
        <DropdownList>
          {statesToUse
            .getList()
            .filter(filterState(stateInput))
            .map(({ name, code }) => (
              <DropdownItem key={code} onMouseDown={selectStateOnMouseDown(code)}>
                <Text>{name}</Text>
              </DropdownItem>
            ))}
        </DropdownList>
      )}
    </Relative>
  );
};

export default StateField;

const statusColor = ({ status }: { status: FormFieldStatus }) => {
  switch (status) {
    case 'warning':
      return css`
        border-color: ${lighten('colors.warning', 30)};

        &:hover {
          border-color: ${lighten('colors.warning', 10)};
        }

        &:focus,
        &:active {
          border-color: ${th('colors.warning')};
        }
      `;
    case 'info':
      return css`
        border-color: ${lighten('colors.info', 30)};

        &:hover {
          border-color: ${lighten('colors.info', 10)};
        }

        &:focus,
        &:active {
          border-color: ${th('colors.info')};
        }
      `;
    case 'success':
      return css`
        border-color: ${lighten('colors.actionPrimary', 30)};

        &:hover {
          border-color: ${lighten('colors.actionPrimary', 10)};
        }

        &:focus,
        &:active {
          border-color: ${th('colors.actionPrimary')};
          border-color: red;
        }
      `;
    case 'none':
    default:
      return css`
        border-color: ${th('colors.disabled')};

        &:hover {
          border-color: ${lighten('colors.textPrimary', 60)};
        }

        &:focus,
        &:active {
          border-color: ${th('colors.textPrimary')};
        }
      `;
  }
};

const StateInput = styled.input`
  border: none;
  outline: none;
  width: 100%;

  &:focus,
  &:active {
    outline: none;
  }
`;

const Relative = styled.div`
  position: relative;
  width: 100%;
`;

const Root = styled.div<{ status: FormFieldStatus }>`
  align-items: center;
  border: 1px solid ${th('colors.disabled')};
  border-radius: ${th('gridUnit')};
  color: ${th('colors.textPrimary')};
  box-sizing: border-box;
  display: flex;
  height: calc(${th('gridUnit')} * 8);
  outline: none;
  padding: 0 calc(${th('gridUnit')} * 2);
  width: 100%;

  ${statusColor};
  ${space};
  ${layout};
`;

const DropdownItem = styled.div`
  align-items: center;
  cursor: pointer;
  color: ${th('colors.textPrimary')};
  display: flex;
  height: calc(${th('gridUnit')} * 8);
  padding: 0 calc(${th('gridUnit')} * 2);

  &:hover {
    background-color: ${th('colors.background')};
  }
`;

const DropdownList = styled.div`
  background-color: ${th('colors.white')};
  border-radius: ${th('gridUnit')};
  box-shadow: 0px 2px 6px ${th('colors.disabled')};
  margin-top: ${th('gridUnit')};
  position: absolute;
  overflow: scroll;

  max-height: calc(${th('gridUnit')} * 8 * 4);
  width: 100%;
  z-index: 10;
`;
