import React from 'react';
import { SpaceProps, PositionProps } from 'styled-system';

import icons from './icons';

import type { IconNames } from './Icon.types';
import { Icon as Root } from './Icon.styles';

export interface Props extends SpaceProps, PositionProps {
  color?: string;
  size?: number;
  name: IconNames;
  onClick?(e: React.MouseEvent<SVGSVGElement>): void;
}

const Icon: React.FunctionComponent<Props> = ({ name, color, size, ...rest }) => {
  const path = icons[name];

  return (
    <Root
      color={color || 'colors.white'}
      size={size || 4}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      {Array.isArray(path) ? (
        path.map((p) => <path key={p} fillRule='evenodd' clipRule='evenodd' d={p} />)
      ) : (
        <path fillRule='evenodd' clipRule='evenodd' d={path} />
      )}
    </Root>
  );
};

export default Icon;
