import React from 'react';
import styled from 'styled-components';

import { th, Expander } from '../../../../react-components/src';

import InvoiceForm from './InvoiceForm';
import InvoiceInfo from './InvoiceInfo';

interface Props {
  payer: any;
  error: string | null;
  couponError: string | null;
  invoiceId: string;
  invoiceCreationDate: string;
  invoiceStatus: string;
  loading: boolean;
  paymentType: string;
  status: 'DRAFT' | 'ACTIVE' | 'FINAL' | 'PENDING';
  article: any;
  handleSubmit(payer: any): any;
  onVatFieldChange(country: string, state: string, postalCode: string, paymentType: string): any;
  applyCoupon(invoiceId: string, couponCode: string): any;
  refreshInvoice(invoiceId: string): any;
  setSelectedCountry(countryCode: string): void;
}

const BillingInfo: React.FC<Props> = ({
  payer,
  article,
  paymentType,
  invoiceId,
  invoiceStatus,
  invoiceCreationDate,
  couponError,
  error,
  status,
  loading,
  handleSubmit,
  onVatFieldChange,
  applyCoupon,
  refreshInvoice,
  setSelectedCountry,
}) => {
  return (
    <Expander mb={6} flex={2} expanded={true} title='1. Details'>
      <Root>
        {status === 'DRAFT' ? (
          <InvoiceForm
            payer={payer}
            article={article}
            loading={loading}
            error={error}
            couponError={couponError}
            applyCoupon={applyCoupon}
            handleSubmit={handleSubmit}
            refreshInvoice={refreshInvoice}
            onVatFieldChange={onVatFieldChange}
            updateSelectedCountry={setSelectedCountry}
          />
        ) : (
          <InvoiceInfo
            payer={payer}
            article={article}
            paymentType={paymentType}
            invoiceStatus={invoiceStatus}
            invoiceId={invoiceId}
            invoiceCreationDate={invoiceCreationDate}
          />
        )}
      </Root>
    </Expander>
  );
};

export default BillingInfo;

const Root = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  padding: calc(${th('gridUnit')} * 4);
  width: 100%;
`;
