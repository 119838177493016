import React from 'react';
import axios from 'axios';

import { Button } from '../../../../react-components';
import { useAuth } from '../../../contexts/Auth';

import { config } from '../../../../config';

interface DownloadPdfProps {
  documentType: 'invoice' | 'receipt';
  invoiceNumber: string;
  dateCreated: string;
  payerId: string;
}

export const DownloadPdf: React.FC<DownloadPdfProps> = ({ invoiceNumber, dateCreated, payerId, documentType }) => {
  const getFileName = () => {
    const date = new Date(dateCreated);
    const parsedDate = `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`;

    return `${documentType}_${invoiceNumber}-${parsedDate}.pdf`;
  };

  const auth = useAuth();

  const getPdf = () => {
    return axios.get(`${config.apiRoot}/${documentType}/${payerId}`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${auth?.data.token}`,
      },
    });
  };

  const downloadFile = async () => {
    const filename = getFileName();
    const { data } = await getPdf();
    const objectURL = URL.createObjectURL(data);

    const downloadLink = document.createElement('a');
    downloadLink.style.display = 'none';
    downloadLink.href = objectURL;
    downloadLink.download = filename;

    downloadLink.dispatchEvent(new MouseEvent('click'));
  };

  return (
    <Button type='primary' size='medium' onClick={downloadFile} mr={3}>
      Download {documentType === 'invoice' ? 'Invoice' : 'Receipt'}
    </Button>
  );
};
