import React, { Fragment } from 'react';
import countryList from 'country-list';

import { Flex, Text, Label, Button } from '../../../../react-components/src';

import { COUNTRY_CODES, PAYMENT_TYPES, indiaStates, usStates } from '../../../types';
import { useAuth, Auth } from '../../../contexts/Auth';
import { DownloadPdf } from './DownloadPdf';

import { config } from '../../../../config';

interface Props {
  invoiceId: string;
  paymentType: string;
  article: any;
  invoiceCreationDate: string;
  invoiceStatus: string;
  payer: any;
}

const CREDIT_CARD = 'Credit Card';
enum InvoiceStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  FINAL = 'FINAL',
}

const InvoiceInfo: React.FunctionComponent<Props> = (props: Props) => {
  const { payer } = props;

  const auth = useAuth();

  if (auth.data.isAuthenticated && payer && payer.email) {
    const address = payer.address;

    return (
      <Fragment>
        <Flex width={'75%'} gap={'8px'}>
          <Flex vertical flex={1}>
            <Label>Payer type</Label>
            <Text>{payer.type}</Text>
          </Flex>
          {institutionDetails(payer)}
        </Flex>
        <Flex mt={2} alignItems={'flex-start'} gap={'8px'}>
          <Flex vertical flex={1}>
            <Label>First Name</Label>
            <Text>{payer.firstName}</Text>
          </Flex>
          <Flex vertical flex={1}>
            <Label>Last Name</Label>
            <Text>{payer.lastName}</Text>
          </Flex>
          <Flex vertical flex={1}>
            <Label>Email</Label>
            <Text>{payer.email}</Text>
          </Flex>
          <Flex vertical flex={1}>
            <Label>Phone Number</Label>
            <Text>{payer.phoneNumber || '-'}</Text>
          </Flex>
        </Flex>
        <Flex mt={2} alignItems={'flex-start'} gap={'8px'}>
          <Flex vertical flex={1}>
            <Label>City</Label>
            <Text>{address && address.city}</Text>
          </Flex>
          <Flex vertical flex={1}>
            <Label>Country</Label>
            <Text>{getCountryName(address)}</Text>
          </Flex>
          <Flex vertical flex={1}>
            <Label>Postal Code</Label>
            <Text>{(address && address.postalCode) || '-'}</Text>
          </Flex>
          <Flex vertical flex={1}>
            <Label>Address</Label>
            <Text>{address && address.addressLine1}</Text>
          </Flex>
        </Flex>
        {pdfDownloadButtons(props)}
        {logoutBtn(auth)}
      </Fragment>
    );
  }

  if (auth.data.isAuthenticated) {
    return notAuthorizedUser(auth);
  }

  return notAuthenticatedUser(auth);
};

export default InvoiceInfo;

function isCreditCardPayment(paymentType: string) {
  return paymentType === CREDIT_CARD;
}

function canDownloadReceipt(
  paymentType: string,
  article: { authorCountry: string },
  payer: { address: { country: string } },
): boolean {
  const matchCountry = article.authorCountry !== 'IN' && payer?.address?.country !== 'IN';
  return isCreditCardPayment(paymentType) && (!config.gstEnabled || matchCountry);
}

const isIndiaCountry = (country: string) => config.gstEnabled && country === COUNTRY_CODES.IN;

const isUsCountry = (country: string) => country === COUNTRY_CODES.US;

function canDownloadInvoice(invoiceStatus: string) {
  if (invoiceStatus === InvoiceStatus.PENDING) return false;
  if (invoiceStatus === InvoiceStatus.DRAFT) return false;
  return true;
}

function isUkCountry(address: { country?: string }) {
  return address.country === COUNTRY_CODES.UK;
}

function getStateName(address: { country?: string; state?: string }): string {
  if (!address.country || !address.state) return '';
  if (isIndiaCountry(address.country)) return `, ${indiaStates.byCode(address.state)}`;
  if (isUsCountry(address.country)) return `, ${usStates.byCode(address.state)}`;

  return '';
}

function getCountryName(address?: { country?: string; state?: string }): string {
  if (!address || !address.country) return '';
  if (isUkCountry(address)) return countryList.getName(COUNTRY_CODES.GB) || '';

  return `${countryList.getName(address.country)}${getStateName(address)}`;
}

function pdfDownloadButtons({ invoiceStatus, invoiceCreationDate, payer, paymentType, invoiceId, article }: Props) {
  return (
    <div style={{ marginTop: '16px', display: 'flex' }}>
      {canDownloadInvoice(invoiceStatus) && (
        <DownloadPdf
          dateCreated={invoiceCreationDate}
          invoiceNumber={invoiceId}
          documentType='invoice'
          payerId={payer.id}
        />
      )}
      {canDownloadReceipt(paymentType, article, payer) && (
        <DownloadPdf
          dateCreated={invoiceCreationDate}
          invoiceNumber={invoiceId}
          documentType='receipt'
          payerId={payer.id}
        />
      )}
    </div>
  );
}

const logoutBtn = (auth: Auth) => {
  return (
    <div style={{ marginTop: '16px', display: 'flex' }}>
      <Button type='secondary' size='medium' onClick={auth.logout}>
        Logout
      </Button>
    </div>
  );
};

function notAuthorizedUser(auth: Auth) {
  return (
    <>
      You are not one of the authors or payer
      {logoutBtn(auth)}
    </>
  );
}

function notAuthenticatedUser(auth: Auth) {
  return (
    <Flex vertical alignItems={'center'}>
      Please login as one of the authors or payer to see the payer information, invoice and receipt
      <Button type='primary' size='medium' onClick={auth.login} mt={3}>
        Login
      </Button>
    </Flex>
  );
}

function institutionDetails(payer: { type: string; organization: string; vatId: string }) {
  if (payer.type !== PAYMENT_TYPES.institution) return <></>;

  return (
    <>
      <Flex vertical flex={1}>
        <Label>Institution name</Label>
        <Text>{payer.organization}</Text>
      </Flex>
      <Flex vertical flex={1}>
        <Label>EC VAT Reg. No</Label>
        <Text>{payer.vatId || '-'}</Text>
      </Flex>
    </>
  );
}
