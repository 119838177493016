import React from 'react';
import { SpaceProps } from 'styled-system';

import { Loader as Root } from './Loader.styles';

export interface Props extends SpaceProps {
  size?: number;
}

const Loader: React.FunctionComponent<Props> = ({ size, ...rest }) => (
  <Root aria-label='loader' size={size || 3} {...rest} />
);

export default Loader;
