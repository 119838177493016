import styled from 'styled-components';

import { th } from '../../../react-components/src';

export const Header = styled.header`
  padding: calc(${th('gridUnit')} * 4) calc(${th('gridUnit')} * 5);
  background-color: ${th('colors.white')};
  justify-content: flex-start;
  align-items: center;
  display: flex;

  & > * {
    margin-right: 40px;
  }
`;
