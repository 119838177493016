const emailRegex = new RegExp(
  /^(([a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/i,
);
const specialCharRegex = new RegExp(/^[ !@#$%^&*()+=_[\]{}\-;:\\|,.<>/?±`~'"§\x00-\x19]*$/);
const phoneNumberRegex = new RegExp(/^[0-9()\-.]+$/);
const postalCodeRegex = new RegExp(/^[a-zA-Z0-9]+[ -]?[a-zA-Z0-9]+$/);

export type ValidationFunction = (value: string) => string | null;

export function combineValidations(value: string, ...validations: Array<ValidationFunction>): string | null {
  return validations.map((validation) => validation(value)).reduce((acc, maybeErrors) => acc || maybeErrors, null);
}

export const validateBlank: ValidationFunction = (value) => {
  if (!value) return 'Required';
  if (!value.trim()) return 'Blank value is forbidden';

  return null;
};

export const validateSpecialCharacters: ValidationFunction = (value) => {
  if (specialCharRegex.test(value)) return 'Special characters only are not permitted';

  return null;
};

export const validateBlankAndSpecial: ValidationFunction = (value) => {
  return validateBlank(value) || validateSpecialCharacters(value);
};

export const validateEmail: ValidationFunction = (value) => {
  if (emailRegex.test(value)) return null;

  return 'Invalid email address';
};

export const validatePhoneNumber: ValidationFunction = (value) => {
  if (phoneNumberRegex.test(value)) return null;

  return 'Invalid phone number format';
};

export function validateMaxLength(maxLength: number): ValidationFunction {
  return (value: string) => {
    if (!!value && value.length <= maxLength) return null;

    return `The maximum length of ${maxLength} exceeded`;
  };
}

export function validateMinLength(minLength: number): ValidationFunction {
  return (value: string) => {
    if (!!value && value.length >= minLength) return null;

    return `Minimum ${minLength} characters needed`;
  };
}

function isAlphaNumeric(char: string): boolean {
  const code = char.charCodeAt(0);

  if (code > 47 && code < 58) return true;
  if (code > 64 && code < 91) return true;
  if (code > 96 && code < 123) return true;

  return false;
}

function selectOnlyAlphaNumeric(value: string): string {
  return value.split('').filter(isAlphaNumeric).join('');
}

export function validateMaxLengthOfAlphaNumeric(maxLength: number): ValidationFunction {
  return (value) => {
    const bare = selectOnlyAlphaNumeric(value);

    return validateMaxLength(maxLength)(bare);
  };
}

export function validateMinLengthOfAlphaNumeric(maxLength: number): ValidationFunction {
  return (value) => {
    const bare = selectOnlyAlphaNumeric(value);

    return validateMinLength(maxLength)(bare);
  };
}

export const validatePostalCode: ValidationFunction = (value) => {
  if (postalCodeRegex.test(value)) return null;

  return 'Must contain only letters, numbers, spaces or dashes';
};

export function validateRejectedValues(...rejectValues: Array<string>): ValidationFunction {
  return (value) => {
    const matches = rejectValues.filter((rej) => rej === value.trim());

    if (matches.length === 0) return null;

    return 'Invalid Value';
  };
}
