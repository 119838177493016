import React from 'react';

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  style?: object;
  className?: string;
  id: string;
  transparent?: boolean;
}

const PaymentIcon: React.FunctionComponent<Props> = ({ style, className, id, transparent }) => (
  <img
    src={`/assets/payment-icons/${transparent ? 'transparent/' : ''}${transparent ? id + '_transparent' : id}.svg`}
    style={style}
    className={className}
    alt={`${id} payment icon`}
  />
);

export default PaymentIcon;
