import React from 'react';

import { Text } from '../../../../../react-components/src';

export function renderError(error: any, serverError: any) {
  if (!error && !serverError) {
    return null;
  }

  if (serverError && !error) {
    return (
      <Text type='warning' key='3dsecure_error'>
        {'Your credit card was declined by the supplier.'}
      </Text>
    );
  }

  if (error && 'name' in error && error.name === 'BraintreeError') {
    if (error.details?.originalError?.code) {
      return (
        <Text type='warning' key='3dsecure_error'>
          {'3D Secure authentication failed.'}
        </Text>
      );
    }

    if (error.details?.originalError?.details?.originalError?.error) {
      return (
        <Text type='warning' key='braintree_error'>
          {error.details.originalError.details.originalError.error.message}
        </Text>
      );
    }
  }

  if (error && 'liabilityShifted' in error && error.liabilityShifted === false) {
    return (
      <Text type='warning' key='3dsecure_error'>
        {'3D Secure authentication failed.'}
      </Text>
    );
  }

  if (error && error.code && error.code === 'HOSTED_FIELDS_FIELDS_EMPTY') {
    return (
      <Text type='warning' key='all_fields_empty'>
        {'All fields are empty'}
      </Text>
    );
  } else if (error && error.code && error.code === 'HOSTED_FIELDS_FIELDS_INVALID') {
    if (error.details && 'invalidFields' in error.details) {
      const invalids = Object.values(error.details.invalidFieldKeys).reduce((acc: any[], invalidFieldKey) => {
        let txt = '';
        if (invalidFieldKey === 'cardholderName') {
          txt = '\u2022 Please enter a valid cardholder name';
        }
        if (invalidFieldKey === 'number') {
          txt = '\u2022 Please enter a valid credit card number';
        }
        if (invalidFieldKey === 'expirationDate') {
          txt = '\u2022 Please enter a valid expiration date';
        }
        if (invalidFieldKey === 'cvv') {
          txt = '\u2022 Please enter a valid CVV';
        }
        acc.push(
          <Text type='warning' key={`${invalidFieldKey}`}>
            {txt}
          </Text>,
        );
        return acc;
      }, []);

      return (
        [
          <Text key={'msg'} type='warning'>
            {'Some payment input fields are invalid: '}
          </Text>,
        ] as any
      ).concat(invalids);
    }
  }
}
