import React from 'react';
import { Flex, Separator, Title } from '../../../../react-components';

interface Props {
  title?: string;
}

const SuccessfulPayment: React.FunctionComponent<Props> = ({ title = 'Payment was sent successfully' }) => (
  <Flex alignItems='center' vertical flex={2}>
    <Flex vertical alignItems='center' style={{ padding: '40px' }}>
      <img src='../../assets/images/hindawi-payment-success.webp' alt='Success' style={{ height: '111px' }} />

      <div style={{ height: '30px' }}>
        <Separator direction='horizontal'></Separator>
      </div>
      <Title type='small'>{title}</Title>
      <div style={{ height: '25px' }}>
        <Separator direction='horizontal'></Separator>
      </div>
    </Flex>
  </Flex>
);

export default SuccessfulPayment;
