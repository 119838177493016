import React from 'react';

import { FormFieldProps } from '../CommonTypes';

import { Textarea as Input } from './Textarea.styles';
import { ResizeOptions } from './TextareaTypes';

export interface Props extends FormFieldProps {
  placeholder?: string;
  resize?: ResizeOptions;
  onFocus?: any;
}

const Textarea: React.FunctionComponent<Props> = ({ name, status, resize, ...rest }) => {
  return <Input id={name} status={status || 'none'} resize={resize || 'both'} {...rest} />;
};

export default Textarea;
