import React from 'react';
import { get } from 'lodash';
import { Field, FieldProps, FormikProps, FieldInputProps } from 'formik';
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';

import Input from '../Input';
import { Flex } from '../../Flex';
import { Label, Text } from '../../Typography';
import { FormFieldProps } from '../CommonTypes';

export interface Props extends FlexboxProps, LayoutProps, SpaceProps {
  name: string;
  label?: string;
  required?: boolean;
  error?: string | null;
  placeholder?: string;
  disabled?: boolean;
  hide?: boolean;
  FormInput?: React.ComponentType<FormFieldProps>;
}

const hasError = (form: FormikProps<unknown>, name: string) => {
  return form.submitCount > 0 || get(form.touched, name);
};

const FormField: React.FunctionComponent<Props> = ({
  name,
  label,
  placeholder,
  required,
  disabled,
  FormInput,
  hide,
  error: givenError,
  ...rest
}) => {
  function showComponent(error: unknown, field: FieldInputProps<unknown>, form: FormikProps<unknown>) {
    if (FormInput)
      return (
        <FormInput
          disabled={disabled}
          status={error ? 'warning' : 'none'}
          placeholder={placeholder}
          formValues={form.values}
          {...field}
        />
      );
    return <></>;
  }

  return hide ? (
    <></>
  ) : (
    <Field name={name} disabled={disabled}>
      {({ field, form }: FieldProps) => {
        const error = (hasError(form, name) && get(form.errors, name)) || givenError;
        return (
          <Flex vertical {...rest}>
            <Label required={!!required} htmlFor={field.name}>
              {label}
            </Label>
            {showComponent(error, field, form)}
            <Flex minHeight={6} justifyContent='flex-start'>
              {error && <Text type='warning'>{error}</Text>}
            </Flex>
          </Flex>
        );
      }}
    </Field>
  );
};

FormField.defaultProps = {
  FormInput: Input,
};

export default FormField;
