import React, { useCallback, useEffect, useState } from 'react';
import { RootState } from 'typesafe-actions';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ASTNode } from 'graphql';
import gql from 'graphql-tag';

import { Loader, Flex, Text, th } from '../../../react-components';

import { InvoicePayment } from './InvoicePayment';
import { PaymentFooter } from './PaymentFooter';
import { PaymentHeader } from './PaymentHeader';
import { BillingInfo } from './BillingInfo';
import { Details } from './Details';

import { oneContext } from '../../../context';

import { invoiceActions, invoiceSelectors } from '../../state/modules/invoice';

import { paymentSelectors, paymentActions, paymentTypes } from '../../state/modules/payment';
import { InvoiceVATDTO, ApplyCouponDTO } from '../../state/modules/invoice/types';
import { COUNTRY_CODES } from '../../types';

interface Props {
  invoiceError: string | null;
  invoiceLoading: boolean;
  invoice: any;
  payerError: string | null;
  payerLoading: boolean;
  creditCardPaymentError: string | null;
  creditCardPaymentLoading: boolean;
  payPalPaymentError: string | null;
  payPalPaymentLoading: boolean;
  getMethodsError: string | null;
  getMethodsLoading: boolean;
  couponError: string | null;
  paymentMethods: Record<string, string>;
  token: string;
  getInvoice(id: string): any;
  getInvoiceVAT(invoiceVATRequest: InvoiceVATDTO): any;
  applyCoupon(applyCouponDTO: ApplyCouponDTO): any;
  updatePayer(payer: any): any;
  recordPayPalPayment(payment: paymentTypes.PayPalPayment): any;
  payWithCard(payload: any): any;
  getPaymentMethods(d?: any): any;
  getClientToken(d?: any): any;
}

const payByPayPal = (recordAction: any, invoice: any) => {
  return (data: any) => {
    return recordAction({
      invoiceId: invoice.invoiceId,
      orderId: data.orderId,
    });
  };
};

const createPayPalOrder: ASTNode = gql`
  mutation createPayPalOrder($invoiceId: ID!) {
    createPayPalOrder(invoiceId: $invoiceId) {
      id
    }
  }
`;

const createPayPalOrderAction = (invoice: any, setError: any) => {
  return async () => {
    try {
      const aa = await oneContext.graphqlAdapter
        .send(createPayPalOrder, {
          invoiceId: invoice.invoiceId,
        })
        .toPromise();

      return aa.data.createPayPalOrder.id;
    } catch (err) {
      setError((err as any).message);
    }
  };
};

const InvoiceDetailsError = () => (
  <Flex mt={20}>
    <Text>Invoice details are not available.</Text>
  </Flex>
);

const PaymentDetails: React.FunctionComponent<Props> = ({
  getInvoiceVAT,
  getInvoice,
  applyCoupon,
  couponError,
  getClientToken,
  invoice,
  invoiceError,
  invoiceLoading,
  //
  updatePayer,
  payerError,
  payerLoading,
  //
  recordPayPalPayment,
  getPaymentMethods,
  payWithCard,
  creditCardPaymentError,
  creditCardPaymentLoading,
  payPalPaymentError,
  payPalPaymentLoading,
  paymentMethods,
  token,
}) => {
  const getDefaultCountry = (invoice: any) => {
    if (invoice.payer?.country) {
      return invoice.payer.country;
    }

    if (invoice.article?.authorCountry === COUNTRY_CODES.IN) {
      return COUNTRY_CODES.IN;
    }

    return undefined;
  };

  const { invoiceId } = useParams() as any;
  const [paypalInvoiceCreationError, setPaypalInvoiceCreationError] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(getDefaultCountry(invoice));

  // * This will only run once
  useEffect(() => {
    getInvoice(invoiceId);
    getPaymentMethods();
    getClientToken();
  }, [invoiceId, getInvoice, getPaymentMethods, getClientToken]);

  const payByCard = useCallback((values: any) => payWithCard({ invoiceId, ...values }), [invoiceId, payWithCard]);

  const paymentContent = () => {
    const invoiceTransactionIsNotDraft = invoice.transaction.status !== 'DRAFT';
    if (invoiceTransactionIsNotDraft) {
      return (
        <>
          <PaymentHeader articleTitle={invoice.article.title} />
          <Root>
            <FormsContainer>
              <BillingInfo
                status={invoice.status}
                payer={invoice.payer}
                paymentType={invoice.payments[0]?.paymentMethod?.name}
                invoiceStatus={invoice.status}
                invoiceId={invoice.invoiceId}
                invoiceCreationDate={invoice.dateCreated}
                error={payerError}
                handleSubmit={updatePayer}
                loading={payerLoading}
                article={invoice.article}
                applyCoupon={(invoiceId, couponCode) => {
                  applyCoupon({ invoiceId, couponCode });
                }}
                refreshInvoice={() => getInvoice(invoice.invoiceId)}
                couponError={couponError}
                onVatFieldChange={(country, state, postalCode, payerType) =>
                  getInvoiceVAT({
                    postalCode,
                    invoiceId,
                    payerType,
                    country,
                    state,
                  })
                }
                setSelectedCountry={setSelectedCountry}
              />
              <InvoicePayment
                ccToken={token}
                methods={paymentMethods}
                invoiceStatus={invoice.status}
                createPayPalOrder={createPayPalOrderAction(invoice, setPaypalInvoiceCreationError)}
                paymentStatus={invoice.payments.map((p: any) => p.status)}
                error={creditCardPaymentError || payPalPaymentError || paypalInvoiceCreationError}
                payByCardSubmit={payByCard}
                payByPayPalSubmit={payByPayPal(recordPayPalPayment, invoice)}
                loading={creditCardPaymentLoading || payPalPaymentLoading}
              />
            </FormsContainer>

            <Details invoice={invoice} selectedCountry={selectedCountry} mt={-44} />
          </Root>
        </>
      );
    }

    return <InvoiceDetailsError />;
  };

  return (function () {
    if (invoiceError) {
      return <InvoiceDetailsError />;
    }

    if (invoiceLoading) {
      return (
        <Flex alignItems='center' vertical flex={2} mt={20}>
          <Text mb={2}>Fetching invoice&hellip;</Text>
          <Loader size={6} />
        </Flex>
      );
    }

    return (
      <Flex vertical height='calc(100% - 62px)' justifyContent='space-between'>
        <Flex vertical>{paymentContent()}</Flex>
        <PaymentFooter />
      </Flex>
    );
  })();
};

const mapStateToProps = (state: RootState) => ({
  invoice: invoiceSelectors.invoice(state),
  invoiceError: invoiceSelectors.invoiceError(state),
  couponError: invoiceSelectors.couponError(state),
  invoiceLoading: invoiceSelectors.invoiceLoading(state),
  payerError: invoiceSelectors.payerError(state),
  payerLoading: invoiceSelectors.payerLoading(state),
  getMethodsError: paymentSelectors.paymentMethodsError(state),
  getMethodsLoading: paymentSelectors.paymentMethodsLoading(state),
  paymentMethods: paymentSelectors.getPaymentMethods(state),
  token: paymentSelectors.getToken(state),
  creditCardPaymentError: paymentSelectors.recordCreditCardPaymentError(state),
  creditCardPaymentLoading: paymentSelectors.recordCreditCardPaymentLoading(state),
  payPalPaymentError: paymentSelectors.recordPayPalPaymentError(state),
  payPalPaymentLoading: paymentSelectors.recordPayPalPaymentLoading(state),
});

export default connect(mapStateToProps, {
  getInvoiceVAT: invoiceActions.getInvoiceVat.request,
  recordPayPalPayment: paymentActions.recordPayPalPayment.request,
  getPaymentMethods: paymentActions.getPaymentMethods.request,
  getClientToken: paymentActions.getClientToken.request,
  payWithCard: paymentActions.recordCardPayment.request,
  updatePayer: invoiceActions.updatePayerAsync.request,
  getInvoice: invoiceActions.getInvoice.request,
  applyCoupon: invoiceActions.applyCouponAction.request,
})(PaymentDetails);

const Root = styled.div`
  width: 100%;
  align-items: flex-start;
  display: flex;
  padding: calc(${th('gridUnit')} * 6) calc(${th('gridUnit')} * 8);
`;

const FormsContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  margin-right: calc(${th('gridUnit')} * 4);
`;
