import { space, layout, flexbox, position, SpaceProps, LayoutProps, FlexboxProps, PositionProps } from 'styled-system';
import styled, { css } from 'styled-components';

export interface FlexProps extends LayoutProps, SpaceProps, FlexboxProps, PositionProps {
  inline?: boolean;
  vertical?: boolean;
  gap?: string | number;
}

const verticalFn = ({ vertical }: { vertical?: boolean }) => {
  if (vertical) {
    return css`
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
    `;
  }

  return css`
    align-items: center;
    flex-direction: row;
    justify-content: center;
  `;
};

const gapFn = ({ gap }: { gap?: string | number }) => {
  if (gap) {
    return css`
      gap: ${gap};
    `;
  }

  return css``;
};

const Flex = styled.div<FlexProps>`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  box-sizing: border-box;
  width: 100%;

  ${gapFn};
  ${verticalFn};
  ${space};
  ${layout};
  ${flexbox};
  ${position};
`;

Flex.defaultProps = {
  inline: false,
  vertical: false,
};

export default Flex;
