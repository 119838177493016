import './fonts/index.css';
import colors from './colors';
import buttons from './buttons';
import { fontSizes, defaultFont } from './typography';

const generateSpaces = (gridUnit = 4): number[] => {
  return Array.from({ length: 100 }, (_, index) => index * gridUnit);
};

const GRID_UNIT = 4;

export type Theme = {
  colors: {
    actionPrimary: string;
    actionSecondary: string;
    background: string;
    furniture: string;
    info: string;
    border: string;
    textPrimary: string;
    textSecondary: string;
    transparent: string;
    warning: string;
    white: string;
    statusPending: string;
    statusInvite: string;
    statusApproved: string;
    disabled: string;
  };
  gridUnit: string;
  sizes: number[];
};

export const theme = {
  colors,
  buttons,
  fontSizes,
  fontFamily: defaultFont,
  gridUnit: `${GRID_UNIT}px`,
  space: generateSpaces(GRID_UNIT),
  sizes: generateSpaces(GRID_UNIT),
};
