import { isEmpty, omitBy, isNil } from 'lodash';
import { useParams } from 'react-router-dom';
import React, { Fragment } from 'react';
import { Formik } from 'formik';

import { FormField, Textarea, Button, Label, Flex } from '../../../../react-components/src';

import { Modal, useModalActions } from '../../../providers/modal';
import { PAYMENT_TYPES, COUNTRY_CODES } from '../../../types';
import {
  validateMaxLengthOfAlphaNumeric,
  validateMinLengthOfAlphaNumeric,
  validateBlankAndSpecial,
  validateRejectedValues,
  validatePhoneNumber,
  combineValidations,
  validatePostalCode,
  validateMinLength,
  validateMaxLength,
  validateBlank,
  validateEmail,
} from './validators';

import VatChargesObserver from './VATChargesObserver';
import ConfirmationModal from './ConfirmationModal';
import IconRadioButton from './IconRadioButton';
import CountryField from './CountryField';
import StateField from './StateField';

import { config } from '../../../../config';

interface Props {
  payer: any;
  error: string | null;
  loading: boolean;
  article: any;
  couponError: string | null;
  handleSubmit(payer: any): any;
  onVatFieldChange(country: string, state: string, postalCode: string, paymentType: string): any;
  applyCoupon(invoiceId: string, couponCode: string): any;
  refreshInvoice(invoiceId: string): any;
  updateSelectedCountry(countryCode: string): void;
}

const FormTextarea = (field: any) => <Textarea height={26} {...field} resize='vertical' style={{ resize: 'none' }} />;

const parseErrors = (errors: any, prefix = ''): any => {
  return Object.entries(errors).reduce((acc, [key, value]) => {
    if (!value) {
      return acc;
    }

    if (typeof value === 'object') {
      return { ...acc, ...parseErrors(value, `${prefix}${key}.`) };
    }
    return {
      ...acc,
      [`${prefix}${key}`]: true,
    };
  }, {});
};

const imperativeValidation = (formFns: any, showModal: any) => () => {
  formFns.validateForm().then((errors: any) => {
    const errorFields = parseErrors(errors);
    if (isEmpty(errorFields)) {
      showModal();
    } else {
      formFns.setTouched(errorFields);
    }
  });
};

const calculateAddressErrors = (address: any) => {
  let errors: any = {};

  errors.addressLine1 = combineValidations(address.addressLine1, validateBlank, validateMaxLength(255));
  errors.city = combineValidations(address.city, validateBlank, validateMaxLength(255));
  errors.country = validateBlank(address.country);
  errors.postalCode = combineValidations(
    address.postalCode,
    validateBlank,
    validatePostalCode,
    validateMinLengthOfAlphaNumeric(4),
    validateMaxLengthOfAlphaNumeric(9),
  );

  if (canSelectState(address.country)) {
    errors.state = combineValidations(address.state, validateBlank, validateRejectedValues('invalid'));
  }

  errors = omitBy(errors, isNil);

  if (isEmpty(errors)) return null;

  return errors;
};

const validateFn = (updateSelectedCountry: (countryCode: string) => void) => (values: any) => {
  let errors: any = {};
  updateSelectedCountry(values.address.country);

  errors.firstName = combineValidations(values.firstName, validateBlankAndSpecial, validateMaxLength(255));
  errors.lastName = combineValidations(values.lastName, validateBlankAndSpecial, validateMaxLength(255));
  errors.email = combineValidations(values.email, validateBlank, validateEmail);
  errors.address = calculateAddressErrors(values.address);
  errors.phoneNumber = combineValidations(
    values.phoneNumber,
    validateBlankAndSpecial,
    validatePhoneNumber,
    validateMinLength(10),
    validateMaxLength(14),
  );

  if (values.type === PAYMENT_TYPES.institution) {
    errors.organization = combineValidations(values.organization, validateBlankAndSpecial, validateMaxLength(255));
  }

  errors = omitBy(errors, isNil);

  return errors;
};

const InvoiceForm: React.FunctionComponent<Props> = ({
  payer,
  article,
  error,
  couponError,
  loading,
  handleSubmit: handleSubmitAction,
  onVatFieldChange,
  applyCoupon,
  refreshInvoice,
  updateSelectedCountry,
}) => {
  const { invoiceId } = useParams() as any;
  const { showModal, hideModal } = useModalActions();

  const { defaultPayer, indianPayer } = createDefaultFormData(article, invoiceId);

  if (!payer) {
    if (isIndianAuthor(article)) {
      payer = indianPayer;
    } else {
      payer = defaultPayer;
    }
  }

  return (
    <Formik
      initialValues={payer}
      validate={validateFn(updateSelectedCountry)}
      onSubmit={({ coupon, ...payer }) => {
        return handleSubmitAction({ invoiceId, ...payer });
      }}
    >
      {({ values, setTouched, handleSubmit, validateForm, setFieldValue }) => {
        return (
          <Fragment>
            <VatChargesObserver
              postalCode={values.address.postalCode}
              country={values.address.country}
              state={values.address.state}
              paymentType={values.type}
              onChange={onVatFieldChange}
            />
            <Flex m={2} vertical>
              <Label required>Who is making the payment?</Label>
              <Flex mt={1} mb={4}>
                <IconRadioButton
                  isSelected={values && values.type && values.type === PAYMENT_TYPES.individual}
                  onClick={() => setFieldValue('type', PAYMENT_TYPES.individual)}
                  icon='user'
                  label='Pay as Individual'
                  mr={1}
                />
                {!isIndianAuthor(article) && (
                  <IconRadioButton
                    isSelected={values && values.type && values.type === PAYMENT_TYPES.institution}
                    onClick={() => setFieldValue('type', PAYMENT_TYPES.institution)}
                    icon='institution'
                    label='Pay as Institution'
                    ml={1}
                  />
                )}
              </Flex>

              {values && values.type && values.type === PAYMENT_TYPES.institution && (
                <Flex>
                  <FormField mr={4} required label='Institution name' name='organization' />
                  <FormField label='EC VAT Reg. No' name='vatId' />
                </Flex>
              )}

              {values && values.type !== null && (
                <Fragment>
                  <Flex>
                    <FormField mr={2} required name='firstName' label='First Name' />
                    <FormField ml={2} required name='lastName' label='Last Name' />
                  </Flex>

                  <Flex>
                    <FormField mr={2} required label='Email' name='email' />
                    <FormField ml={2} required label='Phone Number' name='phoneNumber' />
                  </Flex>

                  <Flex alignItems='flex-start' justifyContent='space-between'>
                    <Flex vertical mr={2}>
                      <FormField required name='address.addressLine1' label='Address' FormInput={FormTextarea} />
                      <FormField
                        key={values.address.country}
                        required
                        label='State'
                        name='address.state'
                        hide={!canSelectState(values.address.country)}
                        FormInput={StateField}
                      />
                    </Flex>
                    <Flex vertical ml={2}>
                      <FormField
                        required
                        label='Country'
                        name='address.country'
                        FormInput={CountryField}
                        disabled={isIndianAuthor(article)}
                      />
                      <FormField required label='City' name='address.city' />
                      <FormField required label='Postal Code' name='address.postalCode' />
                    </Flex>
                  </Flex>

                  <Flex justifyContent='space-between'>
                    <Flex>
                      <FormField
                        error={couponError}
                        placeholder='Insert coupon code here'
                        label='Coupon'
                        name='coupon'
                      />
                      <Button
                        type='secondary'
                        disabled={!values.coupon}
                        size='medium'
                        mb='1'
                        ml='2'
                        onClick={() => applyCoupon(invoiceId, values.coupon)}
                      >
                        Apply
                      </Button>
                    </Flex>
                    <Flex justifyContent='flex-end'>
                      <Button
                        onClick={imperativeValidation(
                          {
                            setTouched,
                            validateForm,
                          },
                          showModal,
                        )}
                        size='medium'
                        alignSelf='flex-end'
                      >
                        Confirm Invoice
                      </Button>
                    </Flex>
                  </Flex>
                </Fragment>
              )}
            </Flex>
            <Modal>
              <ConfirmationModal
                error={error}
                loading={loading}
                onCancel={hideModal}
                onAccept={handleSubmit}
                onRefresh={refreshInvoice}
              />
            </Modal>
          </Fragment>
        );
      }}
    </Formik>
  );
};

export default InvoiceForm;

const isIndianAuthor = (article: any) => config.gstEnabled && article.authorCountry === COUNTRY_CODES.IN;

const isIndiaCountry = (country: string) => config.gstEnabled && country === COUNTRY_CODES.IN;
const isUsCountry = (country: string) => country === COUNTRY_CODES.US;
const canSelectState = (country: string) => isIndiaCountry(country) || isUsCountry(country);

function createDefaultFormData(article: any, invoiceId: string) {
  const indianPayer = {
    invoiceId,
    type: PAYMENT_TYPES.individual,
    address: {
      country: 'IN',
      state: '',
      city: '',
      postalCode: '',
    },
    firstName: article.authorFirstName,
    lastName: article.authorSurname,
    email: article.authorEmail,
    phoneNumber: '',
    coupon: '',
  };

  const defaultPayer = {
    invoiceId,
    type: null,
    address: {
      country: '',
      state: '',
      city: '',
      postalCode: '',
    },
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    coupon: '',
  };

  return { defaultPayer, indianPayer };
}
