export const PAYMENT_TYPES = {
  individual: 'INDIVIDUAL',
  institution: 'INSTITUTION',
};

export const COUNTRY_CODES = {
  GB: 'GB',
  UK: 'UK',
  US: 'US',
  IN: 'IN',
};

export * from './indiaStates';
export * from './statesTypes';
export * from './usStates';
