import { SpaceProps, LayoutProps, FlexboxProps } from 'styled-system';
import React from 'react';

import { Button as Root } from './Button.styles';
import Loader from '../Loader';
import { ButtonSizes, ButtonTypes } from './Button.types';

export interface Props extends SpaceProps, LayoutProps, FlexboxProps {
  size?: ButtonSizes;
  type?: ButtonTypes;
  disabled?: boolean;
  loading?: boolean;
  children?: React.ReactNode;
  onClick(e: React.MouseEvent<HTMLElement>): void;
}

const Button: React.FunctionComponent<Props> = ({ children, onClick, disabled, loading, ...rest }) => {
  return (
    <Root role='button' disabled={disabled} onClick={loading ? undefined : onClick} {...rest}>
      {!disabled && loading ? <Loader size={4} /> : children}
    </Root>
  );
};

Button.defaultProps = {
  size: 'large',
  loading: false,
  type: 'primary',
  disabled: false,
};

export default Button;
