import React from 'react';
import { SpaceProps, TypographyProps } from 'styled-system';

import { Headings, Titles, Color } from './TitleTypes';

import { Title as Root } from './Title.styles';

export interface Props extends SpaceProps, TypographyProps {
  as?: Headings;
  children?: React.ReactNode;
  upper?: boolean;
  ellipsis?: boolean;
  type?: Titles;
  color?: Color;
}

const Title: React.FunctionComponent<Props> = ({ children, upper, ellipsis, type, color, as, ...rest }) => {
  return (
    <Root
      upper={!!upper}
      ellipsis={!!ellipsis}
      as={as || 'h1'}
      type={type || 'primary'}
      color={color || 'light'}
      {...rest}
    >
      {children}
    </Root>
  );
};

export default Title;
