import React from 'react';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import { RootState } from 'typesafe-actions';

import { Overlay } from './Modal.styles';
import { modalSelectors } from './redux';

const Modal = ({ children, isVisible }: { children: React.ReactElement; isVisible: boolean }) => {
  return isVisible ? <Portal>{children}</Portal> : null;
};

const Portal = ({ children }: { children: React.ReactElement }) =>
  createPortal(<Overlay>{children}</Overlay>, document.body);

const mapStateToProps = (state: RootState) => ({
  isVisible: modalSelectors.isVisible(state),
});

export default connect(mapStateToProps)(Modal);
