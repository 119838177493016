import React from 'react';
import { SpaceProps, LayoutProps } from 'styled-system';

import Icon from '../../Icon';
import { FormFieldProps } from '../CommonTypes';
import { Input as InputElement, RelativeParent } from '../CommonStyles';

export type InputTypes = 'text' | 'password';

export interface Props extends FormFieldProps, SpaceProps {
  type?: InputTypes;
  placeholder?: string;
  disabled?: boolean;
  onFocus?: any;
}

const Input: React.FunctionComponent<Props> = ({ name, type, disabled, status, ...rest }) => {
  return (
    <RelativeParent>
      <InputElement id={name} type={type || 'text'} status={status || 'none'} disabled={!!disabled} {...rest} />
      {status === 'warning' && (
        <Icon position='absolute' name='warningFilled' color='colors.warning' top={2} right={2} />
      )}
    </RelativeParent>
  );
};

export default Input;
