import {
  HostedFieldsTokenizePayload,
  threeDSecure,
  hostedFields,
  client,
  dataCollector,
  HostedFieldFieldOptions,
  DataCollector,
  Client,
  HostedFields,
} from 'braintree-web';
import { ThreeDSecure, ThreeDSecureVerifyOptions } from 'braintree-web/three-d-secure';

import { COUNTRY_CODES } from '../../../../types';

const hfStyles = {
  '::placeholder': {
    color: 'rgb(181, 181, 181)',
    opacity: '1',
  },
  input: {
    'font-size': '14px',
    'font-family': 'monospace',
    'letter-spacing': '2px',
    'word-spacing': '4px',
    width: '100%',
    color: 'rgb(91, 91, 91)',
  },
  '.number': {
    'font-family': 'monospace',
    color: 'rgb(91, 91, 91)',
  },
  '.valid': {
    color: 'green',
  },
};

const hfOptions: HostedFieldFieldOptions = {
  number: {
    selector: '#hf-number',
    placeholder: '•••• •••• •••• ••••',
  },
  cvv: {
    selector: '#hf-cvv',
    placeholder: '•••',
  },
  cardholderName: {
    selector: '#hf-name',
    placeholder: 'Name on card',
  },
  expirationDate: {
    selector: '#hf-date',
    placeholder: 'MM/YYYY',
  },
};

export type BtInstances = {
  deviceDataCollector: DataCollector;
  threeDS: ThreeDSecure;
  hf: HostedFields;
  btClient: Client;
};

export async function setupHostedFields(clientToken: string): Promise<BtInstances> {
  const btClient = await client.create({
    authorization: clientToken,
  });

  const hf = await hostedFields.create({
    client: btClient,
    styles: hfStyles,
    fields: hfOptions,
  });

  const threeDS = await threeDSecure.create({
    client: btClient,
    version: 2,
  });

  const deviceDataCollector = await dataCollector.create({ client: btClient });

  return {
    deviceDataCollector,
    btClient,
    threeDS,
    hf,
  };
}

export const removeNonAsci = (text: string): string => {
  return text
    .split('')
    .filter((c) => c.charCodeAt(0) <= 127)
    .join('');
};

export function createThreeDSecurePayload(
  // payer: any,
  total: number,
  cardToken: HostedFieldsTokenizePayload,
): ThreeDSecureVerifyOptions {
  // const address = payer.address;

  // const region = address.country === COUNTRY_CODES.US ? address.state : undefined;

  // const billingAddress = {
  //   givenName: payer.firstName, // removeNonAsci(payer.firstName)  // ASCII-printable characters required, else will throw a validation error
  //   surname: payer.lastName, // removeNonAsci(payer.lastName) // ASCII-printable characters required, else will throw a validation error
  //   phoneNumber: payer.phoneNumber,
  //   streetAddress: address.addressLine1,
  //   countryCodeAlpha2: address.country,
  //   postalCode: address.postalCode,
  //   locality: address.city,
  //   region,
  // };

  const threeDSecureOptions: ThreeDSecureVerifyOptions = {
    amount: total.toString(),
    bin: cardToken.details.bin,
    collectDeviceData: true,
    // billingAddress,
    // email: payer.email,
    nonce: cardToken.nonce,
    onLookupComplete: (data: unknown, next: () => void) => {
      next();
    },
  } as any;

  return threeDSecureOptions;
}
