const globalConfig: any = (window as any)._env_;

export class Config {
  env: 'development' | 'production';
  tenantName: string;
  appUrl: string;
  apiRoot: string;
  gqlRoot: string;
  paypallClientId: string;
  doiNumber: string;

  authServerUrl: string;
  authServerRealm: string;
  authServerClientId: string;
  authIdpHint: string;

  footerHomeLink: string;
  footerPrivacy: string;
  footerEmail: string;
  footerTOS: string;

  appName: string;

  faviconUrl: string;
  logoUrl: string;

  bankDetails: {
    accountName: string;
    accountType: string;
    accountNumber: string;
    sortCode: string;
    swift: string;
    iban: string;
    bankAddress: string;
    beneficiaryAddress: string;
    accountCurrency: string;
  };
  gstAvailabilityStart: Date;
  gstEnabled: boolean;

  constructor() {
    this.env = globalConfig.NODE_ENV || 'development';
    this.appUrl = globalConfig.APP_URL;
    this.apiRoot = globalConfig.API_ROOT;
    this.gqlRoot = globalConfig.GQL_ROOT;
    this.paypallClientId = globalConfig.PP_CLIENT_ID;
    this.doiNumber = globalConfig.DOI_NUMBER;

    this.authServerUrl = globalConfig.AUTH_SERVER_URL;
    this.authServerRealm = globalConfig.AUTH_SERVER_REALM;
    this.authServerClientId = globalConfig.AUTH_SERVER_CLIENT_ID;
    this.authIdpHint = globalConfig.AUTH_IDP_HINT || '';

    this.footerHomeLink = globalConfig.FOOTER_HOME_LINK;
    this.footerPrivacy = globalConfig.FOOTER_PRIVACY;
    this.footerEmail = globalConfig.FOOTER_EMAIL;
    this.tenantName = globalConfig.TENANT_NAME;
    this.footerTOS = globalConfig.FOOTER_TOS;

    this.appName = globalConfig.APP_NAME;
    this.faviconUrl = globalConfig.FAVICON_URL;
    this.logoUrl = globalConfig.LOGO_URL;

    this.bankDetails = {
      accountName: globalConfig.BANK_ACCOUNT_NAME,
      accountType: globalConfig.BANK_ACCOUNT_TYPE,
      accountNumber: globalConfig.BANK_ACCOUNT_NUMBER,
      sortCode: globalConfig.BANK_SORT_CODE,
      swift: globalConfig.BANK_SWIFT,
      iban: globalConfig.BANK_IBAN,
      bankAddress: globalConfig.BANK_ADDRESS,
      beneficiaryAddress: globalConfig.BANK_BENEFICIARY_ADDRESS,
      accountCurrency: globalConfig.BANK_ACCOUNT_CURRENCY,
    };

    this.gstAvailabilityStart = new Date(globalConfig.GST_AVAILABILITY_START);
    this.gstEnabled = this.gstAvailabilityStart <= new Date();
  }
}

export const config = new Config();
