import React from "react";
import { LayoutProps, SpaceProps, FlexProps } from "styled-system";

import { Flex, Text, Title } from "../../../../../react-components/src";
import { FormatUtils } from "../../../../utils/format";

interface Props extends LayoutProps, SpaceProps, FlexProps {
  price: number;
  vat: number;
}

const TotalCharges: React.FC<Props> = ({ price, vat, ...rest }) => (
  <Flex justifyContent="space-between" {...rest}>
    <span style={{ marginRight: "4px" }}>
      <Title type="small">Total</Title>
    </span>
    <Flex justifyContent="flex-start" style={{ width: "100%" }}>
      <Text>(USD)</Text>
    </Flex>
    <Title type="small">
      ${FormatUtils.formatPrice(price + (price * vat) / 100)}
    </Title>
  </Flex>
);

export default TotalCharges;
