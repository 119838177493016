import { DataCollector, HostedFields, ThreeDSecure } from 'braintree-web';
import React, { useEffect, useState } from 'react';

import { Flex, Label, Button } from '../../../../../react-components';
import PaymentIcon from '../PaymentIcon';

import { BtInstances, createThreeDSecurePayload, setupHostedFields } from './hostedFieldsUtils';
import { CardContainer, FormGroup } from './cardContainerStyles';
import { renderError } from './renderError';

interface Props {
  paymentMethodId: string;
  numberField?: any;
  ccToken: string;
  total: number;
  payer: any;

  loading: boolean;
  serverError: string | null;

  handleSubmit: (d: any) => any;
}

const CreditCardForm: React.FunctionComponent<Props> = ({
  paymentMethodId,
  ccToken,
  payer,
  total,

  loading: propsLoading,
  serverError,

  handleSubmit,
}) => {
  const [btInstances, setBtInstances] = useState<BtInstances | null>(null);
  const [isBraintreeReady, setIsBraintreeReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    (async () => {
      try {
        const instances = await setupHostedFields(ccToken);

        setBtInstances(instances);
        setIsBraintreeReady(true);
      } catch (err) {
        console.error('component error:', err);
        setError(err as Error);
      }
    })();
  }, []);

  async function onSubmit() {
    if (!btInstances) {
      return;
    }

    setLoading(true);

    try {
      const cardToken = await btInstances.hf.tokenize();
      const verifyCardOptions = createThreeDSecurePayload(total, cardToken);
      const tdsResponse = await btInstances.threeDS.verifyCard(verifyCardOptions);

      setLoading(false);

      if (!tdsResponse.threeDSecureInfo.liabilityShifted) {
        setError(tdsResponse);
        return;
      }

      setError(null);

      const ccPayload = {
        deviceData: btInstances.deviceDataCollector.deviceData,
        paymentMethodNonce: tdsResponse.nonce,
        paymentMethodId: paymentMethodId,
        payerId: payer.id,
        amount: total,
      };

      handleSubmit(ccPayload);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  }

  return (
    <Flex vertical>
      <Flex justifyContent='flex-start' mb={2}>
        <Label>Credit Card Details</Label>
        <PaymentIcon id='visa' style={{ margin: 3, width: 36 }} />
        <PaymentIcon id='maestro' style={{ margin: 3, width: 36 }} />
        <PaymentIcon id='mastercard' style={{ margin: 3, width: 36 }} />
        <PaymentIcon id='discover' style={{ margin: 3, width: 36 }} />
      </Flex>

      <CardContainer className={isBraintreeReady ? '' : 'disabled'}>
        <Flex justifyContent='flex-start' mb={0} mt={3}>
          <FormGroup style={{ marginRight: '16px' }}>
            <Label required htmlFor='cardholderName'>
              Cardholder Name
            </Label>
            <div className='form-control' id='hf-name' />
          </FormGroup>

          <FormGroup style={{ marginRight: '16px' }}>
            <Label required htmlFor='cardNumber'>
              Card Number
            </Label>
            <div className='form-control' id='hf-number' />
          </FormGroup>

          <FormGroup style={{ marginRight: '16px' }}>
            <Label required htmlFor='expirationDate'>
              Expiration Date
            </Label>
            <div className='form-control' id='hf-date' />
          </FormGroup>

          <FormGroup style={{ marginRight: '16px' }}>
            <Label required htmlFor='cvv'>
              CVV
            </Label>
            <div className='form-control' id='hf-cvv' />
          </FormGroup>

          <Button mt={0} mb={3} size='medium' onClick={onSubmit} loading={loading || propsLoading}>
            Pay
          </Button>
        </Flex>
      </CardContainer>

      {renderError(error, serverError)}
    </Flex>
  );
};

export default CreditCardForm;
