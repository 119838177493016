import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { Formik, FormikErrors } from 'formik';
import styled from 'styled-components';
import { Text, Expander, th } from '../../../../react-components/src';

import Paypal from './Paypal';
import BankTransfer from './BankTransfer';
import ChoosePayment from './ChoosePayment';
import CreditCardForm from './CreditCardForm';
import SuccessfulPayment from './SuccessfulPayment';

import { invoiceSelectors } from '../../../state/modules/invoice';

type PaymentStatus = 'CREATED' | 'PENDING' | 'FAILED' | 'COMPLETED';

interface Props {
  ccToken: string;
  invoice: any;
  error: string | null;
  invoiceCharge: number;
  invoiceIsPaid: boolean;
  loading: boolean;
  invoiceStatus: 'DRAFT' | 'ACTIVE' | 'FINAL' | 'PENDING';
  paymentStatus: PaymentStatus[];
  methods: Record<string, string>;
  payByCardSubmit: (data: any) => void;
  payByPayPalSubmit: (data: any) => void;
  createPayPalOrder: () => Promise<string>;
}

const InvoicePayment: React.FunctionComponent<Props> = ({
  invoice,
  error,
  methods,
  loading,
  invoiceCharge,
  invoiceIsPaid,
  invoiceStatus,
  paymentStatus,
  payByCardSubmit,
  payByPayPalSubmit,
  createPayPalOrder,
  ccToken,
}) => {
  const parsedMethods = useMemo(
    () =>
      Object.entries(methods).map(([id, name]) => ({
        id,
        name,
        isActive: true,
      })),
    [methods],
  );

  let body = null;
  if (invoiceStatus === 'PENDING') {
    body = (
      <Text my='4' ml='4'>
        Your <strong>invoice</strong> is currently pending. You will be contacted soon by our invoicing team. Thank you
        for your understanding.
      </Text>
    );
  } else if (paymentStatus.includes('PENDING')) {
    body = (
      <Text my='4' ml='4'>
        Your <strong>payment</strong> is currently pending. You will be contacted soon by our invoicing team. Thank you
        for your understanding.
      </Text>
    );
  } else if (invoiceIsPaid) {
    body = <SuccessfulPayment />;
  } else {
    body = [
      <Formik
        key={'invoice-payment-form'}
        validate={validateFn(methods)}
        initialValues={
          {
            paymentMethodId: '',
            payerId: invoice && invoice.payer && invoice.payer.id,
            amount: invoiceCharge,
          } as FormValue
        }
        onSubmit={payByCardSubmit}
      >
        {({ setFieldValue, values }) => {
          return (
            <Root id={'InvoicePaymentRoot'}>
              <ChoosePayment methods={parsedMethods} setFieldValue={setFieldValue} values={values} />
              {methods[values.paymentMethodId] === 'Credit Card' && (
                <CreditCardForm
                  ccToken={ccToken}
                  payer={invoice.payer}
                  paymentMethodId={values.paymentMethodId}
                  handleSubmit={payByCardSubmit}
                  total={calculateTotalToBePaid(invoice)}
                  serverError={error}
                  loading={loading}
                />
              )}
              {methods[values.paymentMethodId] === 'Bank Transfer' && (
                <>
                  <BankTransfer invoiceReference={invoice.referenceNumber} />
                  {renderError(error)}
                </>
              )}
              {methods[values.paymentMethodId] === 'Paypal' && (
                <>
                  <Paypal createPayPalOrder={createPayPalOrder} onSuccess={payByPayPalSubmit} />
                  {renderError(error)}
                </>
              )}
            </Root>
          );
        }}
      </Formik>,
    ];
  }

  return (
    <Expander
      title='2. Payment'
      expanded={invoiceStatus !== 'DRAFT'}
      disabled={invoiceStatus === 'DRAFT'}
      onExpand={() => {
        // reset any server error
        error = '';
      }}
    >
      {body}
    </Expander>
  );
};

const mapStateToProps = (state: RootState) => ({
  invoice: invoiceSelectors.invoice(state),
  invoiceError: invoiceSelectors.invoiceError(state),
  invoiceLoading: invoiceSelectors.invoiceLoading(state),
  invoiceCharge: invoiceSelectors.invoiceCharge(state),
  invoiceIsPaid: invoiceSelectors.invoiceIsPaid(state),
});

export default connect(mapStateToProps, {})(InvoicePayment);

// #region styles
const Root = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  padding: calc(${th('gridUnit')} * 4);
  width: 100%;
`;
// #endregion

type FormValue = {
  cardNumber: string;
  expiration: string;
  cvv: string;
  postalCode: string;
  paymentMethodId: string;
  payerId: string;
  amount: number;
};

const validateFn = (methods: Record<string, string>) => (values: FormValue) => {
  if (methods[values.paymentMethodId] === 'Paypal') return {};

  const errors: FormikErrors<any> = {};

  if (!values.cardNumber) {
    errors.cardNumber = 'Required';
  }
  if (!values.expiration) {
    errors.expiration = 'Required';
  }
  if (!values.cvv) {
    errors.cvv = 'Required';
  }
  if (!values.postalCode) {
    errors.postalCode = 'Required';
  }

  return errors;
};

const calculateTotalToBePaid = (invoice: any): number => {
  const initialAPC = invoice.invoiceItem.price;
  let netValue = invoice.invoiceItem.price;
  const { coupons, waivers } = invoice.invoiceItem;
  let reductions = new Array<any>();
  if (waivers) {
    reductions = reductions.concat(...waivers);
  }
  if (coupons && coupons.length) {
    reductions = reductions.concat(...coupons);
  }

  let discount = reductions.reduce((acc, curr) => acc + curr.reduction, 0);
  discount = discount > 100 ? 100 : discount;
  netValue = netValue - (discount * initialAPC) / 100;

  const vatPercent = invoice.invoiceItem.vat;
  const vat = (netValue * vatPercent) / 100;

  return Math.round((netValue + vat + Number.EPSILON) * 100) / 100;
};

const renderError = (error?: string | null) => {
  if (!error) {
    return null;
  }

  let errorText = error;

  if (error.indexOf('INSTRUMENT_DECLINED') > -1) {
    errorText = 'Payment declined, please choose other payment method from PayPal or choose Credit Card payment.';
  }

  return <Text type='warning'>{errorText}</Text>;
};
