import { StyleSheetManager } from 'styled-components';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import React from 'react';

import { theme } from '../../react-components/src';

import { AuthProvider } from './Auth';
import { UserProvider } from './User';

function AppProviders({ store, children }: { store: Store; children: React.ReactNode }): JSX.Element {
  const renderAuthProvider = () => {
    return (
      <AuthProvider>
        <UserProvider>{children}</UserProvider>
      </AuthProvider>
    );
  };
  return (
    <StyleSheetManager shouldForwardProp={isPropValid} enableVendorPrefixes={true}>
      <BrowserRouter>
        <Provider store={store}>
          <ThemeProvider theme={theme}>{renderAuthProvider()}</ThemeProvider>
        </Provider>
      </BrowserRouter>
    </StyleSheetManager>
  );
}

export default AppProviders;
