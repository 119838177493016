import styled from 'styled-components';
import React from 'react';

export const CardContainer = styled('div')`
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 0 16px;

  &.disabled {
    pointer-events: none;

    /* for "disabled" effect */
    opacity: 0.5;
    background: #ccc;
  }

  .braintree-hosted-field {
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    height: calc(4px * 8);
    padding: calc(4px * 2);
    height: 32px;
    border: 1px solid rgb(201, 201, 201);
    padding: 0 0rem;
  }
  .braintree-hosted-field.braintree-hosted-field-focused {
    border-color: rgb(51, 51, 51);
  }
  .braintree-hosted-field.error {
    border-color: rgb(254, 186, 172);
  }

  button {
    height: 33px;
    margin-top: 18px;
  }
`;

export const FormGroup = styled('div')`
  margin-bottom: 15px;

  .label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
  }

  .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition:
      border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition:
      border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s;
    transition:
      border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s;
  }

  #hf-cvv {
    width: 104px;
  }
  #hf-date {
    width: 124px;
  }
`;
