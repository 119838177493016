import React from 'react';
import { SpaceProps, TypographyProps } from 'styled-system';

import { Text as Root } from './Text.styles';
import { FormFieldStatus } from '../../FormElements/CommonTypes';

export type TextTypes = FormFieldStatus | 'primary' | 'secondary' | 'success' | 'warning' | 'info';

export type TextSizes = 'small' | 'normal';

export interface Props extends SpaceProps, TypographyProps {
  children?: React.ReactNode;
  as?: 'span' | 'p';
  type?: TextTypes;
  size?: TextSizes;
}

const Text: React.FunctionComponent<Props> = ({ children, as, type, size, ...rest }) => {
  return (
    <Root as={as || 'span'} type={type || 'primary'} size={size || 'normal'} {...rest}>
      {children}
    </Root>
  );
};

export default Text;
