import React, { useState } from 'react';
import countryList from 'country-list';
import { space, layout } from 'styled-system';
import styled, { css } from 'styled-components';

import { Icon, Text, th, lighten } from '../../../../react-components/src';
import type { FormFieldProps } from '../../../../react-components/src';

const filterCountry = (inputCountry: string) => (c: string) => c.toLowerCase().startsWith(inputCountry.toLowerCase());

const CountryField: React.FunctionComponent<FormFieldProps> = ({ value, onChange, name, status, disabled, onBlur }) => {
  const countries = (countryList.getNames() as []).sort();
  const [expanded, setExpanded] = useState(false);
  const [countryInput, setCountryInput] = useState(() => getCountryName(value));

  const toggleMenu = () => {
    setExpanded((s) => !s);
  };

  function getCountryName(code?: string): string {
    if (code) return countryList.getName(code) || '';

    return '';
  }

  function getCountryCode(country: string) {
    return countryList.getCode(country) || '';
  }

  const selectCountry = (country: string) => {
    onChange(name)(getCountryCode(country));
    onChange('address.state')('');
    setCountryInput(country);
  };

  const selectCountryOnMouseDown = (country: string) => () => {
    selectCountry(country);
  };

  const setCountry = (e: React.FormEvent<HTMLInputElement>) => {
    if (countryList.getCode(e.currentTarget.value)) {
      selectCountry(e.currentTarget.value);
    } else {
      onChange(name)('');
    }
    setCountryInput(e.currentTarget.value);
  };

  return (
    <Relative>
      <Root status={status} style={disabled ? { backgroundColor: 'rgb(239, 239, 239)' } : {}}>
        <CountryInput
          id={name}
          value={countryInput}
          onBlur={(e) => {
            toggleMenu();
            onBlur(e);
          }}
          onFocus={toggleMenu}
          onChange={setCountry}
          disabled={disabled}
        />
        {!disabled && <Icon name={expanded ? 'caretUp' : 'caretDown'} color={'colors.textPrimary'} />}
      </Root>
      {!disabled && expanded && (
        <DropdownList data-test-id={'countryDropdown'}>
          {countries.filter(filterCountry(countryInput)).map((country: string) => (
            <DropdownItem key={country} onMouseDown={selectCountryOnMouseDown(country)}>
              <Text>{country}</Text>
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </Relative>
  );
};

export default CountryField;

// #region styles
const statusColor = ({ status }: { status: any }) => {
  switch (status) {
    case 'warning':
      return css`
        border-color: ${lighten('colors.warning', 30)};

        &:hover {
          border-color: ${lighten('colors.warning', 10)};
        }

        &:focus,
        &:active {
          border-color: ${th('colors.warning')};
        }
      `;
    case 'info':
      return css`
        border-color: ${lighten('colors.info', 30)};

        &:hover {
          border-color: ${lighten('colors.info', 10)};
        }

        &:focus,
        &:active {
          border-color: ${th('colors.info')};
        }
      `;
    case 'success':
      return css`
        border-color: ${lighten('colors.actionPrimary', 30)};

        &:hover {
          border-color: ${lighten('colors.actionPrimary', 10)};
        }

        &:focus,
        &:active {
          border-color: ${th('colors.actionPrimary')};
          border-color: red;
        }
      `;
    case 'none':
    default:
      return css`
        border-color: ${th('colors.disabled')};

        &:hover {
          border-color: ${lighten('colors.textPrimary', 60)};
        }

        &:focus,
        &:active {
          border-color: ${th('colors.textPrimary')};
        }
      `;
  }
};

const CountryInput = styled.input`
  border: none;
  outline: none;
  width: 100%;

  &:focus,
  &:active {
    outline: none;
  }
`;

const Relative = styled.div`
  position: relative;
  width: 100%;
`;

const Root = styled.div<{ status: string }>`
  align-items: center;
  border: 1px solid ${th('colors.disabled')};
  border-radius: ${th('gridUnit')};
  color: ${th('colors.textPrimary')};
  box-sizing: border-box;
  display: flex;
  height: calc(${th('gridUnit')} * 8);
  outline: none;
  padding: 0 calc(${th('gridUnit')} * 2);
  width: 100%;

  ${statusColor};
  ${space};
  ${layout};
`;

const DropdownItem = styled.div`
  align-items: center;
  cursor: pointer;
  color: ${th('colors.textPrimary')};
  display: flex;
  height: calc(${th('gridUnit')} * 8);
  padding: 0 calc(${th('gridUnit')} * 2);

  &:hover {
    background-color: ${th('colors.background')};
  }
`;

const DropdownList = styled.div`
  background-color: ${th('colors.white')};
  border-radius: ${th('gridUnit')};
  box-shadow: 0px 2px 6px ${th('colors.disabled')};
  margin-top: ${th('gridUnit')};
  position: absolute;
  overflow: scroll;

  max-height: calc(${th('gridUnit')} * 8 * 4);
  width: 100%;
  z-index: 10;
`;
