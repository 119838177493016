import { get } from 'lodash';
import Color from 'color';

import { theme, Theme } from './theme';

const th = (themePath: string): string | number => {
  return get(theme, themePath, themePath);
};

const normalizePercent = (num: number) => (num >= 1 && num <= 100 ? num / 100 : num);

const darkenLighten = (original: string, percent: number, theme: Theme, dark?: boolean) => {
  const color = get(theme, original) || original;
  const thisMuch = normalizePercent(percent);

  let converted: Color;
  try {
    converted = Color(color);
  } catch (_) {
    converted = Color('black');
  }

  if (dark) return converted.darken(thisMuch).string();
  return converted.lighten(thisMuch).string();
};

const darken = (original: string, percent: number) => darkenLighten(original, percent, theme, true);

const lighten = (original: string, percent: number) => darkenLighten(original, percent, theme);

export { darken, lighten, th };
