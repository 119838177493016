import React from 'react';
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';

import { SeparatorDirection, SeparatorFraction } from './SeparatorTypes';

import { Separator as Root } from './Separator.styles';

interface Props extends FlexboxProps, LayoutProps, SpaceProps {
  direction?: SeparatorDirection;
  fraction?: SeparatorFraction;
}

const Separator: React.FC<Props> = ({ direction, fraction, ...rest }) => (
  <Root direction={direction || 'vertical'} fraction={fraction || 100} {...rest}></Root>
);

export default Separator;
